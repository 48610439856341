import React from "react";
import ShowJobDetails from "./ShowJobDetails";

const MainShowJobDetails = () => {
  return (
    <div>
      <ShowJobDetails />
    </div>
  );
};

export default MainShowJobDetails;
