import React from "react";
import "./Navbar.css";
import logo from "../Assets/Images/data.png";

const Navbar = () => {
  const isActive = (path) => window.location.pathname === path;

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        {/* Logo */}
        <a className="navbar-brand" href="/">
          <img src={logo} alt="Logo" className="navbar-logo" />
        </a>

        {/* Hamburger Icon */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar Menu */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <a
                className={`nav-link ${isActive("/") ? "active" : ""}`}
                href="/"
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${isActive("/find-jobs") ? "active" : ""}`}
                href="/find-jobs"
              >
                Find Jobs
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${isActive("/about-us") ? "active" : ""}`}
                href="/about-us"
              >
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  isActive("/employers-list") ? "active" : ""
                }`}
                href="/employers-list"
              >
                Employers
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  isActive("/blogs-list") ? "active" : ""
                }`}
                href="/blogs-list"
              >
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  isActive("/our-contact") ? "active" : ""
                }`}
                href="/our-contact"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
