import React from "react";
import "./HomeTotalUsers.css"; // Optional for custom styling if needed

const HomeTotalUsers = () => {
  const stats = [
    { id: 1, value: "2M", description: "2 million daily active users" },
    { id: 2, value: "3000", description: "Over 3000 open job positions" },
    { id: 3, value: "5M", description: "Over 5 million stories shared" },
  ];

  return (
    <div className="hometotalusers-container container py-5">
      <div className="row text-center">
        {stats.map((stat) => (
          <div
            key={stat.id}
            className="hometotalusers-item col-12 col-md-4 mb-4 mb-md-0"
          >
            <h1 className="hometotalusers-value">{stat.value}</h1>
            <p className="hometotalusers-description">{stat.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeTotalUsers;
