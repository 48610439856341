import React from "react";
import "./ContactPage.css";

const ContactPage = () => {
  return (
    <div className="contactpage-container container-fluid">
      {/* Map Section */}
      <div className="row">
        <div className="col-12 p-0">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.228763288322!2d80.22101201480016!3d13.035915490818085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267e1f53ff133%3A0xf75f39c0a08ea5c0!2s37%2F12%2C%20Damodaran%20St%2C%20T%20Nagar%2C%20Chennai%2C%20Tamil%20Nadu%20600017%2C%20India!5e0!3m2!1sen!2sin!4v1674323145821!5m2!1sen!2sin"
            className="contactpage-map"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      {/* Contact Information Section */}
      <div className="row text-center py-5">
        {/* Address Section */}
        <div className="col-12 col-md-4 mb-4">
          <div className="contactpage-icon mb-3">
            <i
              className="bi bi-geo-alt-fill text-primary"
              style={{ fontSize: "2rem" }}
            ></i>
          </div>
          <h5>Address</h5>
          <p>
            NO. 37/12, Ground Floor, <br />
            Doron Complex, Damodaran Street, <br />
            T. Nagar, Chennai - 600017
          </p>
        </div>

        {/* Call Us Section */}
        <div className="col-12 col-md-4 mb-4">
          <div className="contactpage-icon mb-3">
            <i
              className="bi bi-telephone-fill text-primary"
              style={{ fontSize: "2rem" }}
            ></i>
          </div>
          <h5>Call Us</h5>
          <p>
            +91 91766 93350 <br />
            +91 94448 33350 <br />
            +91 44468 28885
          </p>
        </div>

        {/* Email Section */}
        <div className="col-12 col-md-4 mb-4">
          <div className="contactpage-icon mb-3">
            <i
              className="bi bi-envelope-fill text-primary"
              style={{ fontSize: "2rem" }}
            ></i>
          </div>
          <h5>Email</h5>
          <p>admin@srdatatech.com</p>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
