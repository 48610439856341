import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import "./Showblogdetails.css";

const Showblogdetails = (jobDetails) => {
  const location = useLocation();
  const articleId = location.state?.jobDetails;
  console.log(articleId, "articleId");
  const [newArticlesDetails, setNewArticlesDetails] = useState([]);

  useEffect(() => {
    if (articleId) {
      // Fetch the job details from the API using the job ID
      const fetchJobDetails = async () => {
        try {
          const response = await axios.get(
            `https://api.dremerz.net/api/Project_10_Sr_Technologies_Jobs_NewArticles/${articleId}`
          );
          setNewArticlesDetails(response.data); // Save the fetched job details
        } catch (error) {
          console.error("Error fetching job details:", error);
        }
      };

      fetchJobDetails();
    }
  }, [articleId]); // Fetch job details when the jobId changes

  if (!jobDetails) {
    return <div>Loading...</div>; // Show loading text until the job details are fetched
  }
  return (
    <>
      <div className="Showblogdetails-container container">
        <div className="Showblogdetails-header text-center">
          <h1 className="Showblogdetails-title">
            {newArticlesDetails.jobname}
          </h1>
          <p className="Showblogdetails-meta text-muted">
            <span className="Showblogdetails-author">Posted On</span> •
            <span className="Showblogdetails-date">
              {" "}
              {newArticlesDetails.date}
            </span>{" "}
            •
          </p>
        </div>
        <div className="Showblogdetails-image-container">
          <img
            src={`https://api.dremerz.net/uploads/${newArticlesDetails.image}`}
            alt="Blog"
            className="Showblogdetails-image img-fluid"
          />
        </div>
        <div className="Showblogdetails-content mt-4">
          <h2 className="Showblogdetails-subtitle"></h2>
          <p className="Showblogdetails-text"> {newArticlesDetails.location}</p>
        </div>
      </div>
      <section className="showblogdetailsdescription-section py-5">
        <div className="container">
          <h2 className="showblogdetailsdescription-title mb-3">
            {newArticlesDetails.mainheading}
          </h2>
          <p className="showblogdetailsdescription-paragraph mb-3">
            {newArticlesDetails.maindescription}
          </p>
          <p className="showblogdetailsdescription-paragraph mb-5"></p>

          <h3 className="showblogdetailsdescription-learn-title mt-5 mb-3"></h3>
          <div className="row">
            {newArticlesDetails?.headings &&
              Array.isArray(JSON.parse(newArticlesDetails.headings)) &&
              JSON.parse(newArticlesDetails.headings).map((heading, index) => (
                <div className="col-12 mt-4" key={index}>
                  <h4 className="job-description-subtitle">
                    {heading.heading}
                  </h4>
                  <ul className="job-description-list">
                    {Array.isArray(heading.options) &&
                      heading.options.map((option, idx) => (
                        <p key={idx}>{option}</p>
                      ))}
                  </ul>
                </div>
              ))}
          </div>
          <div className="showblogdetailsdescription-quote p-4 rounded">
            <blockquote className="mb-0">
              <p className="mb-2">{newArticlesDetails.salary}</p>
            </blockquote>
          </div>
        </div>
      </section>
    </>
  );
};

export default Showblogdetails;
