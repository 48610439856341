import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import "./Showcompanydetails.css"; // Add your custom styles if needed

const Showcompanydetails = (jobDetails) => {
  const location = useLocation();
  const employerId = location.state?.jobDetails;
  console.log(employerId, "employerId");
  const [newEmployerDetails, setNewEmployerDetails] = useState([]);

  useEffect(() => {
    if (employerId) {
      // Fetch the job details from the API using the job ID
      const fetchJobDetails = async () => {
        try {
          const response = await axios.get(
            `https://api.dremerz.net/api/Project_10_Sr_Technologies_Jobs_NewCompany/${employerId}`
          );
          setNewEmployerDetails(response.data); // Save the fetched job details
        } catch (error) {
          console.error("Error fetching job details:", error);
        }
      };

      fetchJobDetails();
    }
  }, [employerId]); // Fetch job details when the jobId changes

  if (!jobDetails) {
    return <div>Loading...</div>; // Show loading text until the job details are fetched
  }

  return (
    <div className="Showcompanydetails container my-4">
      <div className="card p-4">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-4">
          <div className="d-flex align-items-center">
            <img
              src="https://sr-data-tchnology.netlify.app/wp-content/uploads/2021/05/y12.jpg"
              alt="Company Logo"
              className="Showcompanydetails-logo rounded me-3"
              style={{ width: "80px", height: "80px" }}
            />
            <div>
              <h3 className="Showcompanydetails-title mb-2">
                {newEmployerDetails.jobname}
                <span className="badge bg-success ms-2">
                  <i className="bi bi-patch-check-fill"></i>
                </span>
              </h3>
              <div className="text-muted">
                <span className="me-3">
                  <i className="bi bi-briefcase me-1"></i> Information
                  Technology
                </span>
                <span className="me-3">
                  <i className="bi bi-geo-alt me-1"></i>{" "}
                  {newEmployerDetails.location}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center mt-3 mt-md-0">
            {/* <button className="btn btn-primary me-2">Apply Now</button> */}
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-4">
          <div className="text-muted">
            Application Starts On:{" "}
            <span className="text-danger fw-bold">
              {newEmployerDetails.application_startdate}
            </span>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-8">
            {/* About Company Section */}
            <div>
              <h5 className="fw-bold mb-3">{newEmployerDetails.mainheading}</h5>
              <p className="text-muted">{newEmployerDetails.maindescription}</p>
            </div>
            {newEmployerDetails?.headings &&
              Array.isArray(JSON.parse(newEmployerDetails.headings)) &&
              JSON.parse(newEmployerDetails.headings).map((heading, index) => (
                <div className="col-12 mt-4 " key={index}>
                  <h4 className="job-description-subtitle">
                    {heading.heading}
                  </h4>
                  <ul className="job-description-list text-muted">
                    {Array.isArray(heading.options) &&
                      heading.options.map((option, idx) => (
                        <li key={idx}>{option}</li>
                      ))}
                  </ul>
                </div>
              ))}
          </div>
          <div className="col-md-4">
            <div className="card p-3">
              <h5 className="fw-bold mb-3">Company Details:</h5>
              <ul className="list-unstyled text-muted">
                <li className="mb-2">
                  <i className="bi bi-calendar-event me-2"></i> No.of.Employees:{" "}
                  <span className="fw-bold">
                    {newEmployerDetails.application_enddate}
                  </span>
                </li>
                <li className="mb-2">
                  <i className="bi bi-geo-alt me-2"></i> Location:{" "}
                  <span className="fw-bold">{newEmployerDetails.location}</span>
                </li>
                <li className="mb-2">
                  <i className="bi bi-cash-coin me-2"></i> Net worth: :{" "}
                  <span className="fw-bold">{newEmployerDetails.salary}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Showcompanydetails;
