import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

import "./AboutUsRedirectLabel.css"; // Include your custom CSS if needed

const AboutUsRedirectLabel = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleViewClick = () => {
    navigate("/find-jobs");
  };

  return (
    <div className="aboutusredirectlabel-container d-flex justify-content-center align-items-center text-center">
      <div>
        <h1 className="aboutusredirectlabel-title">
          Your Dream Jobs Are Waiting
        </h1>
        <p className="aboutusredirectlabel-description">
          Over 1 million interactions, 50,000 success stories. Make yours now.
        </p>
        <div className="aboutusredirectlabel-buttons d-flex justify-content-center">
          <button
            className="btn btn-outline-light mx-2"
            onClick={() => handleViewClick()} // Trigger navigate when View is clicked
          >
            Search Job
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutUsRedirectLabel;
