import React from "react";
import "./HomeAbout.css"; // Optional for custom styling if needed

const HomeAbout = () => {
  return (
    <div className="homeabout-container container py-5">
      <div className="row align-items-center">
        {/* Left Side - Image */}
        <div className="col-lg-6 mb-4 mb-lg-0">
          <img
            src="https://apusthemes.com/wp-demo/superio/wp-content/uploads/2021/03/work.jpg" // Replace with your image path
            alt="About us"
            className="homeabout-image img-fluid rounded"
          />
        </div>

        {/* Right Side - Text Content */}
        <div className="col-lg-6">
          <h1 className="homeabout-title mb-3">
            Millions of Jobs. Find the one that suits you.
          </h1>
          <p className="homeabout-description mb-4">
            Search all the open positions on the web. Get your own personalized
            salary estimate. Read reviews on over 600,000 companies worldwide.
          </p>
          <ul className="homeabout-list mb-4">
            <li>Bring to the table win-win survival</li>
            <li>Capitalize on low-hanging fruit to identify</li>
            <li>But I must explain to you how all this</li>
          </ul>

          {/* Employer Logos */}
          <div className="homeabout-employers mt-4 d-flex align-items-center">
            <div className="homeabout-employers-count me-3">
              <i className="bi bi-check-circle-fill text-primary me-2"></i>
              300k+ Employers
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAbout;
