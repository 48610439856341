import React from "react";
import "./Footer.css"; // Optional for custom styling if needed

const Footer = () => {
  return (
    <div className="footer-container">
      {/* Footer Bottom Section */}
      <div className="footer-bottom container py-5">
        <div className="row">
          {/* Contact Information */}
          <div className="col-lg-3 mb-4">
            {/* <div className="footer-brand">
              <img
                src="https://sr-data-tchnology.netlify.app/image/SR_DATA_T.png"
                alt="SR Data Technology"
                className="footer-logo mb-3"
              />
            </div> */}
            <p>
              <h5>Call us</h5>
            </p>
            <p>
              <a href="tel:+919176693350">+91 91766 93350</a> <br />
              <a href="tel:+919444833350">+91 94448 33350</a> <br />
              <a href="tel:+914446828885">+91 44468 28885</a>
              <br></br>
              Social Links : &nbsp;&nbsp;&nbsp;
              <a
                href="https://www.instagram.com/sr_data_2019/?igsh=MTBodXk2eWRubHU1Nw%3D%3D#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="fab fa-instagram"
                  style={{
                    fontSize: "24px",
                    color: "#E4405F",
                    marginTop: "10px",
                  }}
                ></i>
              </a>
            </p>
          </div>

          {/* Links for Candidates */}
          <div className="col-lg-3 mb-4">
            <h5>For Candidates</h5>
            <ul className="list-unstyled">
              <li>
                <a href="find-jobs">Browse Jobs</a>
              </li>
              <li>
                <a href="showcompanydetails">Browse Company</a>
              </li>
            </ul>
          </div>

          {/* Links for Employers */}

          {/* About Us Links */}
          <div className="col-lg-3 mb-4">
            <h5>About Us</h5>
            <ul className="list-unstyled">
              <li>
                <a href="our-contact">Contact Us</a>
              </li>
              <li>
                <a href="about-us">About Us</a>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 mb-4">
            <h5>Reach Us</h5>
            <p>
              <a href="mailto:admin@srdatatech.com">admin@srdatatech.com</a>
            </p>
            <p>
              NO. 37/12, Ground Floor, Doron Complex, Damodaran Street, T.Nagar,
              Chennai - 600017
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-12" style={{ backgroundColor: "#E7F0FE" }}>
        <div className="row">
          <div className="col-lg-4 col-md-2 col-sm-12 text-start"></div>
          <div className="col-lg-8 col-md-8 col-sm-12 text-start">
            <p className="mt-3">
              <strong>&copy; 2025 SR Data Technologies.</strong> All rights
              reserved.
            </p>
          </div>

          <div className="col-lg-2 col-md-2 col-sm-12 text-start"></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
