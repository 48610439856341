import React from "react";
import "./AboutUsHeader.css"; // Add your custom styles here if needed

const AboutUsHeader = () => {
  return (
    <div className="aboutusheader-container">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6 mb-3">
            <img
              src="https://apusthemes.com/wp-demo/superio/wp-content/uploads/2021/03/g1.jpg" // Replace with actual image URL
              alt="Gallery"
              className="img-fluid aboutusheader-image"
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <img
              src="https://apusthemes.com/wp-demo/superio/wp-content/uploads/2021/03/g4.jpg"
              alt="Gallery Top"
              className="img-fluid aboutusheader-image mb-5" // Added margin-bottom for spacing
            />
            <img
              src="https://apusthemes.com/wp-demo/superio/wp-content/uploads/2021/03/g5.jpg"
              alt="Gallery Bottom"
              className="img-fluid aboutusheader-image mt-3"
            />
          </div>

          <div className="col-md-4 col-sm-6 mb-3">
            <img
              src="https://apusthemes.com/wp-demo/superio/wp-content/uploads/2021/03/g5.jpg"
              alt="Gallery Top"
              className="img-fluid aboutusheader-image mb-5" // Added margin-bottom for spacing
            />
            <img
              src="https://apusthemes.com/wp-demo/superio/wp-content/uploads/2021/03/g4.jpg"
              alt="Gallery Bottom"
              className="img-fluid aboutusheader-image mt-3"
            />
          </div>
        </div>
        <div className="row mt-4 text-center aboutusheader-stats">
          <div className="col-md-4 col-sm-6">
            <h2 className="aboutusheader-stat-value">4M</h2>
            <p className="aboutusheader-stat-desc">
              4 million daily active users
            </p>
          </div>
          <div className="col-md-4 col-sm-6">
            <h2 className="aboutusheader-stat-value">12k</h2>
            <p className="aboutusheader-stat-desc">
              Over 12k open job positions
            </p>
          </div>
          <div className="col-md-4 col-sm-6">
            <h2 className="aboutusheader-stat-value">20M</h2>
            <p className="aboutusheader-stat-desc">
              Over 20 million stories shared
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsHeader;
