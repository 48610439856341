import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import bannerimage from "../Assets/Images/newbanner.jpeg";

import "./HomeBanner.css";

const HomeBanner = () => {
  const navigate = useNavigate();
  const [fetchedJobs, setFetchedJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.dremerz.net/api/Project_10_Sr_Technologies_Jobs_NewPosts"
        );
        const result = response.data;
        setFetchedJobs(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleNavigate = () => {
    // Navigate to another page with the selected job and location
    navigate("/find-filtered-jobs", {
      state: { jobname: selectedJob, location: selectedLocation },
    });
  };

  return (
    <>
      <section className="homebanner-container">
        <div className="container text-center text-md-start">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h1 className="homebanner-title">
                There Are <span className="homebanner-highlight">93,178</span>{" "}
                Postings Here For You!
              </h1>
              <p className="homebanner-subtitle">
                Find Jobs, Employment & Career Opportunities
              </p>
              <form className="homebanner-search d-flex flex-column flex-md-row gap-2">
                {/* Dropdown for Job Names */}
                <select
                  className="form-control"
                  value={selectedJob}
                  onChange={(e) => setSelectedJob(e.target.value)}
                >
                  <option value="">Select Job Title</option>
                  {fetchedJobs.map((job) => (
                    <option key={job.id} value={job.jobname}>
                      {job.jobname}
                    </option>
                  ))}
                </select>

                {/* Dropdown for Locations */}
                <select
                  className="form-control"
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.target.value)}
                >
                  <option value="">Select Location</option>
                  {fetchedJobs.map((job) => (
                    <option key={job.id} value={job.location}>
                      {job.location}
                    </option>
                  ))}
                </select>

                {/* Button to navigate */}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleNavigate}
                  disabled={!selectedJob || !selectedLocation}
                >
                  Find Jobs
                </button>
              </form>
              <p className="homebanner-popular mt-3">
                Popular Searches: Designer, Developer, Web, iOS, PHP, Senior,
                Engineer
              </p>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <div className="homebanner-image-container position-relative">
                <img
                  src={bannerimage}
                  alt="Professional"
                  className="img-fluid homebanner-image"
                  // style={{ height: "700px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeBanner;
