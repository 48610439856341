import React, { useState } from "react";
import axios from "axios";
import "./PreFooterForm.css";

const PreFooterForm = () => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    companyName: "",
    email: "",
    phone: "",
    jobTitle: "",
    description: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Replace with your actual API endpoint
      const apiEndpoint =
        "https://api.dremerz.net/api/Project_10_SrDataTech_EnquiryForm";
      await axios.post(apiEndpoint, formData);

      // Reset the form
      setFormData({
        companyName: "",
        email: "",
        phone: "",
        jobTitle: "",
        description: "",
      });

      // Show success alert
      setShowSuccessAlert(true);
      setTimeout(() => setShowSuccessAlert(false), 3000);
      setShowForm(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Failed to submit the form. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="footer-recruiting container py-5">
      <div className="row align-items-center">
        <div className="col-md-8">
          <h2 className="footer-recruiting-title">Recruiting?</h2>
          <p className="footer-recruiting-text">
            Advertise your jobs to millions of monthly users and search 15.8
            million CVs in our database.
          </p>
          <button className="btn btn-primary" onClick={() => setShowForm(true)}>
            Start Recruiting Now
          </button>
        </div>
        <div className="col-md-4 text-center">
          <img
            src="https://www.shutterstock.com/image-vector/3d-plastic-megaphones-icon-loudspeaker-600nw-2436751947.jpg"
            alt="Recruiting"
            style={{ height: "200px", width: "200px", borderRadius: "20px" }}
          />
        </div>
      </div>

      {/* Success Alert */}
      {showSuccessAlert && (
        <div className="alert alert-success text-center mt-4">
          Thank you! Our team will get back to you soon.
        </div>
      )}

      {/* Modal for Form */}
      {showForm && (
        <div className="recruiting-modal">
          <div className="recruiting-modal-content">
            <button
              className="btn-close recruiting-modal-close"
              onClick={() => setShowForm(false)}
            ></button>
            <h3 className="mb-4 text-center">Enquiry Form</h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="companyName" className="form-label">
                  <i className="bi bi-building"></i> Full Name or Company Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  placeholder="Enter your company name"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  <i className="bi bi-envelope"></i> Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter your email address"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  <i className="bi bi-telephone"></i> Phone
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Enter your phone number"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="jobTitle" className="form-label">
                  <i className="bi bi-briefcase"></i> Job Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="jobTitle"
                  name="jobTitle"
                  value={formData.jobTitle}
                  onChange={handleInputChange}
                  placeholder="Enter the job title for vacancies"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  <i className="bi bi-textarea-t"></i> Nature Of Enquiries
                </label>
                <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  rows="4"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder="Describe the job requirements"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreFooterForm;
