import React from "react";
import "./AboutUsDescription.css"; // Add custom styles here if necessary

const AboutUsDescription = () => {
  return (
    <div className="aboutusdescription-container">
      <div className="container">
        <h1 className="aboutusdescription-title text-center mb-4">About Us</h1>
        <p className="aboutusdescription-text text-center">
          Welcome to{" "}
          <strong>
            <span style={{ color: "#F9252E" }}>SR DATA</span>&nbsp;
            <span style={{ color: "#5B2E94" }}>Technologies</span>
          </strong>
          , your trusted partner in recruitment, staffing and HR solutions. We
          are dedicated to connecting businesses with exceptional talent and
          providing innovative workforce solutions that drive success and
          sustainable growth.
        </p>
        <h2 className="aboutusdescription-subtitle mt-5">Who We Are</h2>
        <p className="aboutusdescription-text">
          At{" "}
          <strong>
            <span style={{ color: "#F9252E" }}>SR DATA</span>&nbsp;
            <span style={{ color: "#5B2E94" }}>Technologies</span>
          </strong>
          , we understand the critical role of skilled professionals in shaping
          business excellence. With years of industry expertise, we specialize
          in offering end-to-end recruitment, outsourcing and staffing services
          to meet the unique needs of businesses across various sectors. Whether
          you’re a small startup or a large enterprise, we are here to empower
          your workforce with the right talent.
        </p>
        <h2 className="aboutusdescription-subtitle mt-5">Our Mission</h2>
        <p className="aboutusdescription-text">
          To enable businesses to achieve their goals by delivering top-tier
          recruitment services and HR solutions, while also supporting job
          seekers in realizing their career aspirations.
        </p>
        <h2 className="aboutusdescription-subtitle mt-5">What We Do</h2>
        <ul className="aboutusdescription-list">
          <li>
            <strong>Recruitment Services:</strong> Expert sourcing for
            permanent, part-time and temporary roles across industries such as
            IT, Banking, Manufacturing, Media and more.
          </li>
          <li>
            <strong>Outsourcing Solutions:</strong> Comprehensive HR consulting
            for ESI, PF, Payroll Processing, and compliance with labor laws
            including the Factories Act, Bonus Act, and Labour Act.
          </li>
          <li>
            <strong>Staffing Solutions:</strong> Customized staffing solutions
            to match your requirements, from fresh talent to experienced
            professionals.
          </li>
        </ul>
        <h2 className="aboutusdescription-subtitle mt-5">
          Industries We Serve
        </h2>
        <p className="aboutusdescription-text">
          We proudly support businesses across diverse industries, including:
        </p>
        <ul className="aboutusdescription-list">
          <li>Banking & Finance</li>
          <li>Information Technologies</li>
          <li>Media & Advertising</li>
          <li>Manufacturing & Engineering</li>
          <li>Education & Training</li>
          <li>Customer Support & Call Centers</li>
        </ul>
        <h2 className="aboutusdescription-subtitle mt-5">
          Why Choose{" "}
          <strong>
            <span style={{ color: "#F9252E" }}>SR DATA</span>&nbsp;
            <span style={{ color: "#5B2E94" }}>Technologies</span>
          </strong>
          ?
        </h2>
        <ul className="aboutusdescription-list">
          <li>
            Tailored Solutions: Our services are customized to your business
            needs.
          </li>
          <li>
            Industry Expertise: A team of experienced professionals with deep
            domain knowledge.
          </li>
          <li>
            Legal Compliance: Adherence to all statutory and regulatory
            requirements.
          </li>
          <li>
            Commitment to Quality: Delivering exceptional results to support
            your growth.
          </li>
        </ul>
        <p className="aboutusdescription-text mt-4">
          At{" "}
          <strong>
            <span style={{ color: "#F9252E" }}>SR DATA</span>&nbsp;
            <span style={{ color: "#5B2E94" }}>Technologies</span>
          </strong>
          , we are more than just a service provider. We are your strategic
          partner in building a robust workforce and achieving operational
          excellence. Whether you’re looking to hire top talent or streamline
          your HR processes, we are here to deliver unmatched service quality
          and results.
        </p>
        <h3 className="aboutusdescription-closing text-center mt-5">
          Let’s Build the Future Together!
        </h3>
        <p className="aboutusdescription-text text-center">
          Contact us today to learn how{" "}
          <strong>
            <span style={{ color: "#F9252E" }}>SR DATA</span>&nbsp;
            <span style={{ color: "#5B2E94" }}>Technologies</span>
          </strong>{" "}
          can help you meet your staffing, recruitment, and outsourcing needs.
        </p>
      </div>
    </div>
  );
};

export default AboutUsDescription;
