import React from "react";
import "./HomeClients.css"; // Optional for custom styling if needed
import c1 from "../Assets/Images/CBSLGroup.png";
import c2 from "../Assets/Images/EGS.png";
import c3 from "../Assets/Images/NRS.png";
import c4 from "../Assets/Images/Paytm(1).png";
import c5 from "../Assets/Images/Swift.png";

const HomeClients = () => {
  const clients = [
    {
      id: 1,
      src: "https://cbslgroup.in/assets/images/logo.png",
      alt: "Client 1",
    },
    {
      id: 2,
      src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8U6HjE9y18UYxUrD_z7Rv51rp3tgXmuoDXQ&s",
      alt: "Client 2",
    },
    {
      id: 3,
      src: "https://paytmblogcdn.paytm.com/wp-content/uploads/2022/09/Paytm-800x500-1-800x500.png",
      alt: "Client 3",
    },
    {
      id: 4,
      src: c3,
      alt: "Client 4",
    },
    {
      id: 5,
      src: c5,
      alt: "Client 5",
    },
    {
      id: 6,
      src: "https://paytmblogcdn.paytm.com/wp-content/uploads/2022/09/Paytm-800x500-1-800x500.png",
      alt: "Client 7",
    },
    {
      id: 7,
      src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8U6HjE9y18UYxUrD_z7Rv51rp3tgXmuoDXQ&s",
      alt: "Client 7",
    },
    // Add more client objects as needed
  ];

  return (
    <div className="homeclients-container">
      <div className="homeclients-row d-flex flex-nowrap">
        {clients.map((client) => (
          <div
            key={client.id}
            className="homeclients-item col-6 col-md-4 col-lg-2"
          >
            <img
              src={client.src}
              alt={client.alt}
              className="img-fluid clientimage"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeClients;
