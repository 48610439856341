import React, { useEffect, useState } from "react";
import "./ShowJobDetails.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";

const ShowJobDetails = (jobDetails) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [countdown, setCountdown] = useState(45);

  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    fullname: "",
    mobile: "",
    email: "",
    address: "",
    pincode: "",
    experience: "",
    role: "",
    resume: null,
  });
  const [newJobDetails, setNewJobDetails] = useState([]);
  const [relatedJobDetails, setRelatedJobDetails] = useState([]);

  const location = useLocation();
  const jobId = location.state?.jobDetails;
  console.log(jobId, "jobId");

  useEffect(() => {
    if (showAlert) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            setShowAlert(false);
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [showAlert]); // Depend only on `showAlert`

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.dremerz.net/api/Project_10_Sr_Technologies_Jobs_NewPosts"
        );
        const fetchedJobs = response.data;
        setRelatedJobDetails(fetchedJobs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (jobId) {
      // Fetch the job details from the API using the job ID
      const fetchJobDetails = async () => {
        try {
          const response = await axios.get(
            `https://api.dremerz.net/api/Project_10_Sr_Technologies_Jobs_NewPosts/${jobId}`
          );
          setNewJobDetails(response.data); // Save the fetched job details
        } catch (error) {
          console.error("Error fetching job details:", error);
        }
      };

      fetchJobDetails();
    }
  }, [jobId]); // Fetch job details when the jobId changes

  if (!jobDetails) {
    return <div>Loading...</div>; // Show loading text until the job details are fetched
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, resume: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    // Append all formData fields dynamically
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    // Append additional fields explicitly
    if (newJobDetails) {
      if (newJobDetails.jobname)
        formDataToSend.append("jobname", newJobDetails.jobname);
      if (newJobDetails.location)
        formDataToSend.append("location", newJobDetails.location);
      if (newJobDetails.jobtype)
        formDataToSend.append("jobtype", newJobDetails.jobtype);
      if (newJobDetails.companyname)
        formDataToSend.append("companyname", newJobDetails.companyname);
      if (newJobDetails.startdate)
        formDataToSend.append("startdate", newJobDetails.startdate);
      if (newJobDetails.enddate)
        formDataToSend.append("enddate", newJobDetails.enddate);
      if (newJobDetails.id) formDataToSend.append("jobid", newJobDetails.id);
    }

    try {
      const response = await fetch(
        "https://api.dremerz.net/api/Project_10_Sr_Technologies_Jobs_UserApplication",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      if (response.ok) {
        setAlertMessage("Application submitted successfully!");
        setAlertType("success");
        setShowAlert(true);
        setCountdown(45);
        setShowForm(false);
      } else {
        const errorData = await response.json();
        setAlertMessage(
          `Failed to submit the application: ${errorData.message}`
        );
        setAlertType("error");
        setShowAlert(true);
        setCountdown(45);
      }
    } catch (error) {
      console.error("Error submitting application:", error);
      setAlertMessage("An error occurred. Please try again.");
      setAlertType("error");
      setShowAlert(true);
      setCountdown(45);
    }
  };

  return (
    <>
      <div className="showjobdetails-container container my-5">
        {/* Job Header */}
        <div className="row align-items-center showjobdetails-header p-4 mb-4">
          <div className="col-md-2 text-center">
            <img
              src="https://sr-data-tchnology.netlify.app/wp-content/uploads/2021/05/y2.jpg" // Replace with actual logo
              alt="Company Logo"
              className="showjobdetails-logo"
            />
          </div>
          <div className="col-md-6">
            <h2 className="showjobdetails-title">{newJobDetails.jobname} </h2>
            <p className="showjobdetails-meta">
              <span>
                <i className="bi bi-briefcase"></i> {newJobDetails.jobtype}
              </span>{" "}
              |{" "}
              <span>
                <i className="bi bi-geo-alt"></i> {newJobDetails.location}
              </span>{" "}
              |{" "}
              <span>
                <i className="bi bi-calendar"></i> {newJobDetails.companyname}
              </span>{" "}
            </p>
          </div>
          <div className="col-md-4 text-end">
            <p className="text-primary">
              Application starts: {newJobDetails.application_startdate}
            </p>
            <p className="text-danger">
              Application ends: {newJobDetails.application_enddate}
            </p>

            <button
              className="btn btn-primary me-2"
              onClick={() => setShowForm(true)}
            >
              Apply Now
            </button>
          </div>
        </div>

        {/* Job Details */}
        <div className="row">
          <div className="col-md-8">
            {/* Job Description */}
            <div className="job-description-container container my-5">
              <div className="row">
                <div className="col-12">
                  <h2 className="job-description-title">
                    {newJobDetails.mainheading}
                  </h2>
                  <p className="job-description-text">
                    {newJobDetails.maindescription}
                  </p>
                </div>

                {newJobDetails?.headings &&
                  Array.isArray(JSON.parse(newJobDetails.headings)) &&
                  JSON.parse(newJobDetails.headings).map((heading, index) => (
                    <div className="col-12 mt-4" key={index}>
                      <h4 className="job-description-subtitle">
                        {heading.heading}
                      </h4>
                      <ul className="job-description-list">
                        {Array.isArray(heading.options) &&
                          heading.options.map((option, idx) => (
                            <li key={idx}>{option}</li>
                          ))}
                      </ul>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/* Job Overview */}
          <div className="col-md-4">
            <div className="card shadow-sm showjobdetails-overview">
              <div className="card-body">
                <h5>Job Overview</h5>
                <hr />
                <p>
                  <i className="bi bi-calendar-event"></i> Date Posted: June 20,
                  2021
                </p>
                <p>
                  <i className="bi bi-geo-alt"></i> Location:{" "}
                  {newJobDetails.location}
                </p>
                <p>
                  <i className="bi bi-currency-dollar"></i> Salary:{" "}
                  {newJobDetails.salary}/ Per Annum
                </p>
                <p>
                  <i className="bi bi-calendar-x"></i> Experience:
                  {newJobDetails.experience} Years
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* related jobs */}
      <div className="Relatedjobs container my-4">
        <h4 className="Relatedjobs-title mb-3">Related Jobs</h4>
        <div className="Relatedjobs-list">
          {relatedJobDetails?.map((job) => (
            <div key={job.id} className="Relatedjobs-card card mb-3">
              <div className="card-body d-flex align-items-center">
                <div className="row w-100">
                  <div className="col-2">
                    <img
                      src={`https://api.dremerz.net/uploads/${job.image}`}
                      alt="Company Logo"
                      className="Relatedjobs-logo rounded-circle"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <p>{job.companyname}</p>
                  </div>
                  <div className="col-8">
                    <h5 className="Relatedjobs-title mb-1">{job.jobname}</h5>
                    <div className="Relatedjobs-details text-muted mb-2">
                      <span className="me-3">
                        <i className="bi bi-briefcase me-1"></i> {job.jobtype}
                      </span>
                      <span className="me-3">
                        <i className="bi bi-geo-alt me-1"></i> {job.location}
                      </span>
                      <span>
                        <i className="bi bi-cash-coin me-1"></i> {job.salary}{" "}
                        /Per Annum
                      </span>
                    </div>
                  </div>
                  <div className="col-2">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => setShowForm(true)}
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* apply form  */}
      {showForm && (
        <div>
          {/* Background overlay */}
          <div
            className="position-fixed top-0 start-0 w-100 h-100"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.8)", // Lighter semi-transparent overlay
              zIndex: 1040,
            }}
          ></div>

          {/* Modal */}
          <div className="modal show d-block" style={{ zIndex: 1050 }}>
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header bg-primary text-white">
                  <h5 className="modal-title">Apply for the Job</h5>
                  <button
                    type="button"
                    className="btn-close btn-close-white"
                    onClick={() => setShowForm(false)}
                  ></button>
                </div>
                <div className="modal-body p-4">
                  <form
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                    className="p-3"
                  >
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <label
                          htmlFor="fullname"
                          className="form-label fw-bold"
                        >
                          Full Name
                        </label>
                        <input
                          type="text"
                          id="fullname"
                          name="fullname"
                          className="form-control shadow-sm"
                          placeholder="Enter your full name"
                          value={formData.fullname}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <label htmlFor="mobile" className="form-label fw-bold">
                          Mobile
                        </label>
                        <input
                          type="tel"
                          id="mobile"
                          name="mobile"
                          className="form-control shadow-sm"
                          placeholder="Enter your mobile number"
                          value={formData.mobile}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <label htmlFor="email" className="form-label fw-bold">
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control shadow-sm"
                          placeholder="Enter your email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <label htmlFor="pincode" className="form-label fw-bold">
                          Pincode
                        </label>
                        <input
                          type="text"
                          id="pincode"
                          name="pincode"
                          className="form-control shadow-sm"
                          placeholder="Enter your area pincode"
                          value={formData.pincode}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <label
                          htmlFor="experience"
                          className="form-label fw-bold"
                        >
                          Years of Experience
                        </label>
                        <input
                          type="number"
                          id="experience"
                          name="experience"
                          className="form-control shadow-sm"
                          placeholder="Enter years of experience"
                          value={formData.experience}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <label htmlFor="role" className="form-label fw-bold">
                          Previous Role
                        </label>
                        <input
                          type="text"
                          id="role"
                          name="role"
                          className="form-control shadow-sm"
                          placeholder="Enter your previous role"
                          value={formData.role}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="mb-4">
                      <label htmlFor="address" className="form-label fw-bold">
                        Address
                      </label>
                      <textarea
                        id="address"
                        name="address"
                        className="form-control shadow-sm"
                        rows="3"
                        placeholder="Enter your complete address"
                        value={formData.address}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>

                    <div className="mb-4">
                      <label htmlFor="resume" className="form-label fw-bold">
                        Upload Resume (PDF or Word)
                      </label>
                      <input
                        type="file"
                        id="resume"
                        name="resume"
                        className="form-control shadow-sm"
                        accept=".pdf,.doc,.docx"
                        onChange={handleFileChange}
                        required
                      />
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg shadow"
                      >
                        Apply Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal show={showAlert} onHide={() => setShowAlert(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {alertType === "success" ? "Success" : "Error"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{alertMessage}</p>
          <p style={{ fontWeight: "bold", color: "red" }}>
            Closing in {countdown} seconds...
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={alertType === "success" ? "primary" : "danger"}
            onClick={() => setShowAlert(false)}
          >
            Close Now
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShowJobDetails;
