import React from "react";
import HomeBanner from "./HomeBanner";
import HomePopularJob from "./HomePopularJob";
import HomeFeaturedJobs from "./HomeFeaturedJobs";
// import HomeTestimonials from "./HomeTestimonials";
import HomeClients from "./HomeClients";
import HomeAbout from "./HomeAbout";
import HomeTotalUsers from "./HomeTotalUsers";
import HomeArticles from "./HomeArticles";

const MainHome = () => {
  return (
    <div>
      <HomeBanner />
      <HomePopularJob />
      <HomeFeaturedJobs />
      <HomeAbout />

      <HomeTotalUsers />
      {/* <HomeTestimonials /> */}

      <HomeClients />

      <HomeArticles />
    </div>
  );
};

export default MainHome;
