import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate

import "./HomePopularJob.css"; // Optional custom styling

const HomePopularJob = () => {
  const categories = [
    { icon: "fa-money-bill-wave", title: "Accounting / Finance" },
    { icon: "fa-bullhorn", title: "Marketing" },
    { icon: "fa-paint-brush", title: "Design" },
    { icon: "fa-code", title: "Development" },
    { icon: "fa-users", title: "Human Resource" },
    { icon: "fa-tasks", title: "Project Management" },
    { icon: "fa-headset", title: "Customer Service" },
    { icon: "fa-briefcase-medical", title: "Health and Care" },
    { icon: "fa-car", title: "Automotive Jobs" },
  ];
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleViewClick = () => {
    console.log();

    navigate("/find-jobs");
  };

  return (
    <div className="homepopularjob container text-center my-5">
      {/* Header */}
      <h2 className="homepopularjob-title mb-5">Popular Job Categories</h2>
      <p className="homepopularjob-subtitle mb-5">
        2025 jobs live — 293 added today.
      </p>

      {/* Categories Grid */}
      <div className="row g-4">
        {categories.map((category, index) => (
          <div className="col-6 col-md-4 col-lg-4" key={index}>
            <div className="homepopularjob-card p-3 border rounded text-center">
              <div className="homepopularjob-icon mb-3">
                <i className={`fa ${category.icon} fa-2x text-primary`}></i>
              </div>
              <p className="homepopularjob-title mb-0">{category.title}</p>
            </div>
          </div>
        ))}
      </div>

      {/* View All Button */}
      <button
        className="btn btn-primary mt-5"
        onClick={() => handleViewClick()} // Trigger navigate when View is clicked
      >
        View All
      </button>
    </div>
  );
};

export default HomePopularJob;
