import React from "react";
import "./AboutUsHowItWorks.css";

const AboutUsHowItWorks = () => {
  return (
    <section className="aboutushowitworks-section py-5">
      <div className="container text-center">
        <h2 className="aboutushowitworks-title mb-2">How It Works?</h2>
        <p className="aboutushowitworks-subtitle mb-5">
          Job for anyone, anywhere
        </p>
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 text-center mb-4">
            <img
              src="https://sr-data-tchnology.netlify.app/wp-content/uploads/2021/05/f1.png"
              alt="Resume Assessment"
              className="aboutushowitworks-icon mb-3"
            />
            <h4 className="aboutushowitworks-heading">
              Free Resume Assessments
            </h4>
            <p className="aboutushowitworks-text">
              Employers on average spend 31 seconds scanning resumes to identify
              potential matches.
            </p>
          </div>
          <div className="col-12 col-md-4 text-center mb-4">
            <img
              src="https://sr-data-tchnology.netlify.app/wp-content/uploads/2021/05/f2.png"
              alt="Job Fit Scoring"
              className="aboutushowitworks-icon mb-3"
            />
            <h4 className="aboutushowitworks-heading">Job Fit Scoring</h4>
            <p className="aboutushowitworks-text">
              Our new fit meter shows you which jobs are most relevant to your
              skills and interests.
            </p>
          </div>
          <div className="col-12 col-md-4 text-center mb-4">
            <img
              src="https://sr-data-tchnology.netlify.app/wp-content/uploads/2021/05/f3.png"
              alt="Help Every Step of the Way"
              className="aboutushowitworks-icon mb-3"
            />
            <h4 className="aboutushowitworks-heading">
              Help Every Step of the Way
            </h4>
            <p className="aboutushowitworks-text">
              Our career advice section is full of information to help you
              identify the right fit.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsHowItWorks;
