import React from "react";
import AboutUsHeader from "./AboutUsHeader";
import AboutUsDescription from "./AboutUsDescription";
import AboutUsRedirectLabel from "./AboutUsRedirectLabel";
import AboutUsHowItWorks from "./AboutUsHowItWorks";

const Aboutusmain = () => {
  return (
    <>
      <AboutUsHeader />
      <AboutUsDescription />
      <AboutUsRedirectLabel />
      <AboutUsHowItWorks />
    </>
  );
};

export default Aboutusmain;
