import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useEffect } from "react";
import MainHome from "./Home/MainHome";
import FindJobs from "./FindJobs/FindJobs";
import Navbar from "./CommonComponents/Navbar";
import Footer from "./CommonComponents/Footer";
import Aboutusmain from "./AboutUs/Aboutusmain";
import EmployerList from "./EmployerList/EmployerList";
import Blogs from "./Blogs/Blogs";
import ContactPage from "./ContactPage/ContactPage";
import PreFooterForm from "./CommonComponents/PreFooterForm";
import MainShowJobDetails from "./ShowJobDetails/MainShowJobDetails";
import Showcompanydetails from "./Showcompanydetails/Showcompanydetails";
import Showblogdetails from "./Showblogdetails/Showblogdetails";
import FindFilteredJobs from "./FindJobs/FindFilteredJobs";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top-left of the page
  }, [pathname]);

  return null;
};

function NavigationHandler() {}

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="content">
        <BrowserRouter>
          <ScrollToTop />
          <NavigationHandler />
          <Routes>
            <Route path="/" element={<MainHome />} />
            <Route path="/home" element={<MainHome />} />
            <Route path="/find-jobs" element={<FindJobs />} />
            <Route path="/find-filtered-jobs" element={<FindFilteredJobs />} />
            <Route path="/about-us" element={<Aboutusmain />} />
            <Route path="/employers-list" element={<EmployerList />} />
            <Route path="/blogs-list" element={<Blogs />} />
            <Route path="/our-contact" element={<ContactPage />} />
            <Route path="/showjobdetails" element={<MainShowJobDetails />} />
            <Route
              path="/showcompanydetails"
              element={<Showcompanydetails />}
            />
            <Route path="/showblogdetails" element={<Showblogdetails />} />\{" "}
          </Routes>
        </BrowserRouter>
        <PreFooterForm />

        <Footer />
      </div>
    </div>
  );
}

export default App;
