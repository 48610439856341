import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./EmployerList.css";

const EmployerList = () => {
  const [employers, setEmployers] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredEmployers, setFilteredEmployers] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate hook

  // Fetch data from the API
  useEffect(() => {
    const fetchEmployers = async () => {
      try {
        const response = await fetch(
          "https://api.dremerz.net/api/Project_10_Sr_Technologies_Jobs_NewCompany"
        );
        const data = await response.json();
        setEmployers(data); // Set the fetched data to state
        setFilteredEmployers(data); // Set the filtered data to state
      } catch (error) {
        console.error("Error fetching employer data:", error);
      }
    };

    fetchEmployers();
  }, []);

  // Handle search
  const handleSearch = () => {
    const searchTerm = search.toLowerCase();
    const filtered = employers.filter(
      (employer) =>
        employer.jobname.toLowerCase().includes(searchTerm) ||
        employer.location.toLowerCase().includes(searchTerm)
    );
    setFilteredEmployers(filtered);
  };

  const handleViewClick = (employer) => {
    console.log(employer.id, "employer.id");

    navigate("/showcompanydetails", { state: { jobDetails: employer.id } });
  };

  return (
    <div className="employerlist-container container my-4">
      {/* Search Bar */}
      <div className="row mb-4">
        <div className="col-12 d-flex">
          <input
            type="text"
            className="form-control employerlist-search me-2"
            placeholder="Search Company Here..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button className="btn btn-primary" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>

      {/* Employer List */}
      <div className="row">
        {filteredEmployers.length > 0 ? (
          filteredEmployers.toReversed().map((employer) => (
            <div key={employer.id} className="col-12 col-md-4 col-lg-3 mb-4">
              <div className="card employerlist-card shadow-sm">
                <div className="card-body text-center">
                  <div className="employerlist-logo mb-3">
                    <img
                      src={`https://api.dremerz.net/uploads/${employer.image}`}
                      alt={employer.jobname}
                      className="rounded-circle"
                      width="60"
                      height="60"
                    />
                  </div>
                  <h5 className="employerlist-name">{employer.jobname}</h5>
                  <p className="employerlist-location text-muted mb-1">
                    <i className="bi bi-geo-alt"></i> {employer.location}
                  </p>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleViewClick(employer)} // Trigger navigate when View is clicked
                  >
                    View Details
                  </button>
                </div>
                <span className="badge bg-success employerlist-featured">
                  Featured
                </span>
              </div>
            </div>
          ))
        ) : (
          <div className="col-12 text-center">
            <p className="text-muted">
              No companies found matching your search.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployerList;
