import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate

import "./HomeFeaturedJobs.css"; // Add CSS file for styling

const HomeFeaturedJobs = () => {
  const [fetchedJobs, setFetchedJobs] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate hook

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.dremerz.net/api/Project_10_Sr_Technologies_Jobs_NewPosts"
        );
        const fetchedJobs = response.data;
        console.log(response.data, "response.data()");

        setFetchedJobs(fetchedJobs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleViewClick = () => {
    navigate("/find-jobs");
  };

  return (
    <div className="homefeaturedjobs-container">
      <div className="homefeaturedjobs-section">
        <h2 className="homefeaturedjobs-title text-center">Featured Jobs</h2>
        <p className="homefeaturedjobs-subtitle text-center">
          Know your worth and find the job that qualifies your life
        </p>
        <div className="row">
          {fetchedJobs
            .slice(-10)
            .reverse()
            .map((job) => (
              <div key={job.id} className="col-md-6 mb-4">
                <div className="homefeaturedjobs-card card p-4">
                  <div className="row">
                    {/* Left column with image */}
                    <div className="col-md-4">
                      <div className="homefeaturedjobs-logo">
                        <img
                          src={`https://api.dremerz.net/uploads/${job.image}`}
                          alt={job.jobname}
                          className="img-fluid"
                        />
                      </div>
                    </div>

                    {/* Right column with text */}
                    <div className="col-md-8">
                      <h5 className="homefeaturedjobs-job-title">
                        {job.jobname}
                      </h5>
                      <p className="homefeaturedjobs-category mt-2">
                        <i className="fa fa-briefcase me-1"></i>{" "}
                        {job.companyname} | &nbsp;
                        <i className="fa fa-clock me-1"></i> {job.jobtype}{" "}
                      </p>
                      <p className="homefeaturedjobs-location">
                        <i className="fa fa-map-marker-alt me-1"></i>{" "}
                        {job.location}
                      </p>

                      <button
                        className="btn btn-primary mt-2"
                        onClick={() => handleViewClick(job)} // Trigger navigate when View is clicked
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* View All Button */}
      <div
        className="d-flex"
        style={{ width: "100%", justifyContent: "center" }}
      >
        <button
          className="btn btn-primary mt-5 dflex"
          onClick={() => handleViewClick()} // Trigger navigate when View is clicked
        >
          View All
        </button>
      </div>
    </div>
  );
};

export default HomeFeaturedJobs;
