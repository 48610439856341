import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "./FindJobs.css";

const FindJobs = () => {
  const location = useLocation();
  const { jobname, location: locationFilter } = location.state || {}; // Destructure filters from state

  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [filters, setFilters] = useState({
    jobtype: "",
    experience: "",
    location: locationFilter || "",
    jobname: jobname || "",
  });

  const navigate = useNavigate();

  // Fetch jobs from API
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(
          "https://api.dremerz.net/api/Project_10_Sr_Technologies_Jobs_NewPosts"
        );
        const jobData = response.data.map((job) => ({
          id: job.id,
          image: job.image,
          jobname: job.jobname,
          location: job.location,
          jobtype: job.jobtype || "Full Time",
          experience: job.experience || "Fresh",
        }));
        setJobs(jobData);

        // Apply initial filters
        const initialFilteredJobs = jobData.filter((job) =>
          Object.entries(filters).every(
            ([key, val]) =>
              val === "" || job[key]?.toLowerCase() === val.toLowerCase()
          )
        );
        setFilteredJobs(initialFilteredJobs);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };
    fetchJobs();
  }, [filters]);

  // Handle filtering
  const handleFilterChange = (field, value) => {
    const updatedFilters = { ...filters, [field]: value };
    setFilters(updatedFilters);

    const filtered = jobs.filter((job) =>
      Object.entries(updatedFilters).every(
        ([key, val]) =>
          val === "" || job[key]?.toLowerCase() === val.toLowerCase()
      )
    );
    setFilteredJobs(filtered);
  };

  // Handle "View" button click
  const handleViewClick = (job) => {
    navigate("/showjobdetails", { state: { jobDetails: job.id } });
  };

  return (
    <div className="findjobs-container container-fluid">
      <div className="row">
        {/* Filters Section for Desktop */}
        <div className="findjobs-filters col-md-3 bg-light p-4 d-none d-md-block">
          {["jobtype", "experience", "location"].map((field) => (
            <div className="card shadow-sm mb-4" key={field}>
              <div
                className="card-header text-black"
                style={{ backgroundColor: "#E7F0FE" }}
              >
                <h5 className="mb-0">
                  Filter by {field.charAt(0).toUpperCase() + field.slice(1)}
                </h5>
              </div>
              <div className="card-body">
                {[...new Set(jobs.map((job) => job[field].trim()))].map(
                  (value) => (
                    <div className="form-check mb-2" key={value}>
                      <input
                        type="radio"
                        className="form-check-input"
                        name={field}
                        id={`${field}-${value}`}
                        onChange={() => handleFilterChange(field, value)}
                        checked={filters[field] === value}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`${field}-${value}`}
                        style={{ cursor: "pointer" }}
                      >
                        {value}
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Filters Section for Mobile */}
        <div className="findjobs-filters-mobile col-12 d-md-none p-2">
          {["jobtype", "experience", "location"].map((field) => (
            <div className="mb-3" key={field}>
              <label className="form-label fw-bold" htmlFor={`mobile-${field}`}>
                Filter by {field.charAt(0).toUpperCase() + field.slice(1)}
              </label>
              <select
                className="form-select"
                id={`mobile-${field}`}
                value={filters[field]}
                onChange={(e) => handleFilterChange(field, e.target.value)}
              >
                <option value="">All {field}</option>
                {[...new Set(jobs.map((job) => job[field].trim()))].map(
                  (value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  )
                )}
              </select>
            </div>
          ))}
        </div>

        {/* Job Listings Section */}
        <div className="findjobs-listings col-12 col-md-9 p-4">
          <h6>
            Showing {filteredJobs.length} of {jobs.length} results
          </h6>
          <div className="row">
            {filteredJobs.map((job) => (
              <div key={job.id} className="col-12 mb-4">
                <div className="row align-items-center shadow-sm p-3 border rounded">
                  <div className="col-md-2 text-center">
                    <img
                      src={`https://api.dremerz.net/uploads/${job.image}`}
                      alt={job.jobname}
                      style={{
                        height: "100px",
                        width: "100px",
                        objectFit: "cover",
                      }}
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-8">
                    <h5>{job.jobname}</h5>
                    <p className="mb-1">Location: {job.location}</p>
                    <span className="badge bg-primary">{job.jobtype}</span>
                  </div>
                  <div className="col-md-2 text-center">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => handleViewClick(job)}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindJobs;
