import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

import "./Blogs.css"; // Optional for custom styling if needed

const Blogs = () => {
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate hook

  // Fetch data from the API
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(
          "https://api.dremerz.net/api/Project_10_Sr_Technologies_Jobs_NewArticles"
        );
        const data = await response.json();
        setArticles(data); // Set the fetched data to state
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    fetchArticles();
  }, []);

  const handleViewClick = (article) => {
    console.log(article.id, "article.id");

    navigate("/showblogdetails", { state: { jobDetails: article.id } });
  };

  return (
    <div className="blogspage-container container py-5">
      <div className="text-center mb-5">
        <h2 className="blogspage-title">Recent News Articles</h2>
        <p className="blogspage-subtitle">
          Fresh job related news content posted each day.
        </p>
      </div>
      <div className="row">
        {articles.length > 0 ? (
          articles.map((article) => (
            <div
              key={article.id}
              className="blogspage-item col-12 col-md-6 col-lg-4 mb-4"
            >
              <div className="card h-100">
                <img
                  src={`https://api.dremerz.net/uploads/${article.image}`}
                  className="card-img-top"
                  alt={article.jobname}
                  style={{ height: "250px", objectFit: "fill" }}
                />
                <div className="card-body">
                  <h5 className="card-title">{article.jobname}</h5>
                  <p className="card-text">{article.location}</p>
                  <a
                    href="#"
                    className="blogspage-readmore"
                    onClick={() => handleViewClick(article)} // Trigger navigate when View is clicked
                  >
                    Read More &rarr;
                  </a>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-12 text-center">
            <p className="text-muted">No articles found.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;
